import * as React from "react"
import { graphql } from "gatsby"
import FAQCard from "../components/FAQCard"
import SymptomsCard from "../components/SymptomsCard"
import AdmissionGreetingCard from "../components/AdmissionGreetingCard"

import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";

const TreatmentTemplate = ({ pageContext, data }) => {
  const translations = {
    en: {
      directoryButtonText: 'Back to Treatment Methods',
      makeAppointmentButtonText: 'Book an Appointment',
    },
    ch: {
      directoryButtonText: '返回 治療方法',
      makeAppointmentButtonText: '聯絡我們',
    }
  }
  return (
    <Layout context={pageContext} openGraph={{
      url: pageContext.localizedUrls[pageContext.locale],
      title: pageContext.title,
      description: pageContext.description,
      images: [
        {
          url: `https://www.spinecentre.com.hk${data.firestore.page.content.headerImage}`
        }
      ]
    }}>
      <PageHeader
        title={data.firestore.page.content[pageContext.locale]["headerTitle"]}
        imageUrl={data.firestore.page.content.headerImage}
      />
      <main className="my-12 flex flex-col justify-evenly w-full space-y-6 max-w-full lg:flex-row lg:space-y-0">
        <div className="mx-8 lg:pl-8 lg:pr-4">
          <article className="prose-sm lg:prose" dangerouslySetInnerHTML={{__html: data.firestore.page.content[pageContext.locale]['html']}}></article>
          <div className="mt-8 flex space-x-4">
            <a href={`/${pageContext.locale}/treatments`} className="text-white bg-brick rounded-md px-6 py-3">{translations[pageContext.locale]['directoryButtonText']}</a>
            <a href={`/${pageContext.locale}/about-us/contact`} className="text-white bg-coconut rounded-md px-6 py-3">{translations[pageContext.locale]['makeAppointmentButtonText']}</a>
          </div>
        </div>
        <aside className="items-center flex flex-col space-y-6 lg:items-start lg:px-8">
          <AdmissionGreetingCard/>
          <SymptomsCard/>
          <FAQCard/>
        </aside>
      </main>
    </Layout>
  )
}

export default TreatmentTemplate

export const query = graphql`
  query TreatmentTemplateQuery($pageId: ID) {
    firestore {
      page(id: $pageId) {
        content
      }
    }
  }
`